<footer [appStoryblok]="_editable">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-4 me-auto contact">
        <h3 class="mb-4">{{ theme?.content?.email_signup }}</h3>
        @defer {
        <app-email-form [theme]="theme"></app-email-form>
        } @placeholder {
        <div></div>
        }
      </div>
      <div
        class="col-6 col-lg-3 col-xl-2"
        *ngFor="let blok of theme?.content?.footer_menu; let first = first"
      >
        <ndc-dynamic
          *ngIf="components"
          [ndcDynamicComponent]="components[blok.component]"
          [ndcDynamicInputs]="blok"
        >
        </ndc-dynamic>
      </div>
    </div>
  </div>
</footer>
<div class="container small subfooter mt-auto d-flex flex-column flex-lg-row">
  <span class="small order-1 order-lg-0 mb-3 mb-lg-0"
    >© {{ date | date : "YYYY" }}, Daily Orders Pty Ltd.</span
  >
  <span class="ms-lg-5 order-0 order-lg-1 small mb-3 mb-lg-0 sublinks">
    <ng-container *ngFor="let blok of theme?.content?.footer_sub_menu">
      <ndc-dynamic
        *ngIf="components"
        [ndcDynamicComponent]="components[blok.component]"
        [ndcDynamicInputs]="blok"
      >
      </ndc-dynamic>
    </ng-container>
  </span>
</div>
